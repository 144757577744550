// import { getOrderDetails } from '@/services/order'
import loading from '@/utils/loading.json'
import { getShopItemLabelsId } from '@/services/product'
import createTable from '@/components/createTable'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
// import Utils from '@/utils/Utils'
// import { json } from 'express'
export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  data () {
    return {
      urlParams: null,
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    async init () {
      const _this = this
      Object.assign(this.titleObject, { title: `${_this.$t('menu.shopItemLabels')} Id: `, data: _this.urlParams.id })
      _this.setGlobalLoading(true)
	  const res = await getShopItemLabelsId(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.$message({
		    type: 'success',
		    message: _this.$t('message.loadSuccess')
		  })
		  _this.custName = res.data.shop_item_label
	  }
	  _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'Label', item_id: _this.urlParams.id }).then(res => {
        if (res.status === 200) {
          _this.historyTableData = res.data.versions
        }
      })
      // if (this.custName.shop_payments.length > 0) {
      //   Object.keys(this.custName.shop_payments[0]).forEach(item => {
      //     if (item === 'member_actions') return
      //     const index = this.tableUserDataItem.findIndex(fditem => fditem.prop === item)
      //     this.tableUserDataItem[index].label = this.custName.shop_payments[0][item].name
      //   })
      // }
    },
    handleEditClick () {
      this.$router.push({
        name: 'goodsManagerCommonLabelsEdit',
        params: {
          id: this.$route.params.id
        }
      })
    }
  }
}
